<template>
  <div class="card mb-5 mb-xl-10">
    <div
      class="card-header border-0 cursor-pointer bg-success"
      role="button"
      data-bs-toggle="collapse"
      data-bs-target="#entity_organogram"
      aria-expanded="true"
      aria-controls="form"
    >
      <div class="card-title m-0">
        <h3 class="fw-bolder text-light m-0">Entity Organogram</h3>
      </div>
    </div>

    <div id="entity_organogram" class="collapse show">
      <div class="collapse show">
        <div class="card-body border-top p-4">
          <div class="current" data-kt-stepper-element="content">
            <Form
              @submit="formSubmit()"
              class="form"
              :validation-schema="AssociationSchema"
            >
              <div class="row p-4">
                <div class="col-md-4">
                  <label class="form-label required">Entity Type</label>
                  <br />
                  <!--end::Label-->
                  <el-select
                    class="form-select-solid"
                    placeholder="Select Entity Type"
                    v-model="batch.entity_type"
                    filterable
                    style="width: 100%"
                  >
                    <el-option value="aeosib">AEOSIB</el-option>
                    <el-option value="seip">SEIP</el-option>
                    <el-option value="edc_butex">EDC_BUTEX</el-option>
                  </el-select>
                </div>
                <div class="col-md-2">
                  <button
                    type="button"
                    class="btn btn-success mt-8 py-3"
                    style="
                      padding-top: 8px !important;
                      padding-bottom: 8px !important;
                    "
                    @click="showTree()"
                  >
                    Search
                  </button>
                </div>
              </div>
            </Form>
            <div class="p-4" v-if="showTreeEntity === true">
              <div class="card">
                <!-- <div
                class="card-header border-0 cursor-pointer bg-success"
                role="button"
                data-bs-toggle="collapse"
                data-bs-target="#register_trainee"
                aria-expanded="true"
                aria-controls="form"
              >
                <div class="card-title m-0">
                  <h3 class="fw-bolder text-light m-0">Entity Organogram</h3>
                </div>
              </div> -->
                <div class="m-5">
                  <!-- <button
                    type="button"
                    class="btn btn-info float-right"
                    data-toggle="modal"
                    data-target="#exampleModalLong"
                  >
                    Add New
                  </button> -->
                  <!-- Modal -->
                  <div v-if="batch.entity_type === 'aeosib'">
                    <Tree
                      :nodes="aeosibdata"
                      :search-text="searchText"
                      :use-icon="true"
                      show-child-count
                      use-row-delete
                      @nodeExpanded="onNodeExpanded"
                      @update:nodes="onUpdate"
                      @nodeClick="onNodeClick"
                    />
                  </div>
                  <div v-if="batch.entity_type === 'seip'">
                    <Tree
                      :nodes="seipdata"
                      :search-text="searchText"
                      :use-icon="true"
                      show-child-count
                      use-row-delete
                      @nodeExpanded="onNodeExpanded"
                      @update:nodes="onUpdate"
                      @nodeClick="onNodeClick"
                    />
                  </div>
                  <div v-if="batch.entity_type === 'edc_butex'">
                    <Tree
                      :nodes="edc_butex"
                      :search-text="searchText"
                      :use-icon="true"
                      show-child-count
                      use-row-delete
                      @nodeExpanded="onNodeExpanded"
                      @update:nodes="onUpdate"
                      @nodeClick="onNodeClick"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import Tree from "vue3-tree";
import "vue3-tree/dist/style.css";

export default {
  components: {
    Tree,
  },
  data() {
    return {
      batch: {},
      showTreeEntity: false,
    };
  },
  methods: {
    showTree() {
      this.showTreeEntity = true;
    },
  },
  setup() {
    const aeosibdata = ref([
      {
        id: 1,
        label: "Chief Co-ordinator",
        nodes: [
          {
            id: 2,
            label: "Coordinator(Monitoring And Evaluation)",
            nodes: [
              {
                id: 3,
                label: "Assistance Coordinatior",
              },
            ],
          },
          {
            id: 4,
            label: "Coordinator(Finance And ProcureMent)",
            nodes: [
              {
                id: 5,
                label: "Assistance Coordinatior",
              },
            ],
          },
          {
            id: 6,
            label: "Coordinator(Job Placement And Database)",
            nodes: [
              {
                id: 7,
                label: "Assistance Coordinatior",
              },
            ],
          },
        ],
      },
    ]);
    const seipdata = ref([
      {
        id: 1,
        label: "Chief Co-ordinator",
        nodes: [
          {
            id: 2,
            label: "Coordinator(Monitoring And Evaluation)",
            nodes: [
              {
                id: 3,
                label: "Assistance Coordinatior",
              },
            ],
          },
        ],
      },
    ]);
    const edc_butex = ref([
      {
        id: 1,
        label: "Adviser",
      },
      {
        id: 2,
        label: "Director",
        nodes: [
          {
            id: 3,
            label: "Deputy Director",
          },
          {
            id: 4,
            label: "Assistant Director",
          },
        ],
      },
      {
        id: 2,
        label: "Officer Administration",
        nodes: [
          {
            id: 3,
            label: "Officer Accounts",
          },
          {
            id: 4,
            label: "Office Assistant",
          },
        ],
      },
    ]);

    const searchText = ref("");
    const onNodeExpanded = (node, state) => {
      console.log("state: ", state);
      console.log("node: ", node);
    };

    const onUpdate = (nodes) => {
      console.log("nodes:", nodes);
    };

    const onNodeClick = (node) => {
      console.log(node.id);
    };
    return {
      edc_butex,
      seipdata,
      aeosibdata,
      searchText,
      onNodeExpanded,
      onNodeClick,
      onUpdate,
    };
  },
};
</script>

<style lang="scss">
.tree {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-top: 5px;
}
.tree ul {
  margin-left: 1em;
  position: relative;
}
.tree ul ul {
  margin-left: 0.5em;
}
.tree ul:before {
  content: "";
  display: block;
  width: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  border-left: 1px solid;
}

.tree li {
  margin: 0;
  padding: 0 1em;
  line-height: 2em;
  color: #369;
  font-weight: 700;
  position: relative;
}
.tree ul li:before {
  content: "";
  display: block;
  width: 10px;
  height: 0;
  border-top: 1px solid;
  margin-top: -1px;
  position: absolute;
  top: 1em;
  left: 0;
}
.tree ul li:last-child:before {
  background: #fff;
  height: auto;
  top: 1em;
  bottom: 0;
}
.indicator {
  margin-right: 5px;
}
.tree li a {
  text-decoration: none;
  color: #369;
}
</style>
